<template>
  <div>
    <div class="row">
      <div class="col-xl-4 mb-5">
        <div class="panel active">
          <label class="num-label">{{stateList[state].index}}</label>
          <label class="num-label">{{stateList[state].texts}}</label>
        </div>
      </div>
      <!-- <div class="col-xl-3 mb-2">
          <div class="panel"></div>
      </div>
      <div class="col-xl-3 mb-2">
          <div class="panel"></div>
      </div>-->
      <div class="col-xl-12 mb-2">
          <transition name="plus-icon" mode="out-in">
            <component :is="componentName"></component>
          </transition>
      </div> 
    </div>
  </div>
</template>
<script>
import MyInfo from '../MyInfo.vue';
import ExtraInfo from '../ExtraInfo';
export default {
  components: {
    MyInfo,
    ExtraInfo
  },
  data(){
    return{
      componentName:"MyInfo",
      state:0,
      stateList:[
        {
          index:1,
          texts:"请先完善个人信息"
        },
        {
          index:2,
          texts:"补充额外信息"
        },
      ]
    }
  },
  methods:{
    openExtraInfoForm(){
      this.componentName = "ExtraInfo";
      this.state = 1
    },
    openMyInfoForm(){
      this.componentName = "MyInfo"
      this.state = 0
    },
  }
};
</script>
<style scoped>
.plus-icon-enter-active{
    transition: all .4s;
  }
  .plus-icon-enter{
     opacity: 0;
  }
  .plus-icon-leave-active{
    transition: all .4s;

  }
  .plus-icon-leave-to{
    transform: translateY(50px);

    opacity: 0;
  }
  .plus-icon-enter-to{
     opacity: 1;
  }
.panel {
  width: 100%;
  height: 5rem;
  border: 0.5rem solid white;
  border-radius: 25px;
  background: #3699ff;
  color: white;
}
.num-label {
  line-height: 4.2rem;
  margin-left: 1.5rem;
  font-size: 30px;
}
.y {
  background: #f6b195;
  color: white;
}
.active {
  border: 0.5rem solid #d5e8f8 !important;
}
.p {
  background: #c06c84;
  color: white;
}
.pd {
  background: #6c5b7b;
  color: white;
}
</style>