<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <el-card>
          <h1>额外信息</h1>
          <br />
          <div class="col-xl-12 mb-2">
            身高(cm):
          </div>
          <div class="col-xl-12 mb-2">
            <el-input placeholder="身高(cm)" v-model="form.height"></el-input>
          </div>
          <div class="col-xl-12 mb-2">
            体重(kg):
          </div>
          <div class="col-xl-12 mb-2">
            <el-input placeholder="体重(kg)" v-model="form.weight"></el-input>
          </div>
          <div class="col-xl-12 mb-2">
            鞋码:
          </div>
          <div class="col-xl-12 mb-2">
            <el-input placeholder="鞋码" v-model="form.shoes"></el-input>
          </div>

          <div class="col-xl-12 myInput mb-2">
            <div class="col-xl-12">
              <label>是否需要接站</label>
              <el-switch v-model="form.pickUp"></el-switch>
            </div>
          </div>

          <div class="col-xl-12 mb-2" v-if="form.pickUp">
            请选择接站车站:
          </div>
          <div class="col-xl-12 mb-2" v-if="form.pickUp">
            <el-select v-model="form.station" placeholder="请选择接站车站" style="width: 100%">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="col-xl-12 mb-2" v-if="form.pickUp">
            <el-input placeholder="车次" v-model="form.trainNumber"></el-input>
          </div>

          <div class="col-xl-12 mb-2" v-if="form.pickUp">
            <el-input
              placeholder="到达日期（例：2021年04月05日 17:53）"
              v-model="form.arriveDate"
            ></el-input>
          </div>
          <div class="col-xl-12 mb-2">
            <div class="my-button-div">
              <button
                class="btn btn-primary my-button"
                @click="saveUserExtraInfo()"
              >
                保存
              </button>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      imageUrl: "",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: sessionStorage.getItem("systemToken"),
      },
      form: {
        pickUp: false,
        arriveDate: "",
        weight: "",
        height: "",
        shoes:"",
        station: "",
        trainNumber: "",
      },
      options: [{
        value: '不需要接站',
        label: '不需要接站'
      }, {
        value: '北京站',
        label: '北京站'
      }, {
        value: '北京西站',
        label: '北京西站'
      }],

    };
  },
  methods: {
    initUserExtraInfo() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/userDetails/getUserExtraInfoByUserId", //后端请求的路径
        method: "post",
      }).then((response) => {
        this.form = response.data;
      });
    },
    saveUserExtraInfo() {
      axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/userDetails/updateUserExtraInfoByUserId", //后端请求的路径
        method: "post",
        data: this.form,
      }).then((response) => {
        console.log(response)
        if (response.status === 0) {
          if (this.$route.path === "/main") {
            this.$axios({
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: sessionStorage.getItem("systemToken"),
              },
              url: "/api/user/updateUserInfoByUserId", //后端请求的路径
              method: "post",
              data: {
                firstLogin: false,
              },
            });
            this.$store.commit("changeFirstLogin", false);
            if(response.payStatus === 0){
              this.$router.push("/ChooseDormitory");
              this.$message({
                message: "完成设置，可以开始选宿舍！",
                type: "success",
              });
            }else {
              this.$message({
                message: "完成设置，请完成缴费！",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "更新数据成功",
              type: "success",
            });
          }

          window.scrollTo(0, 0);
        } else {
          this.$message({
            message: "发生错误，原因：" + response.msg,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    this.initUserExtraInfo();
  },
};
</script>
<style scoped>
.block {
  display: inline-block;
}
.my-select {
  width: 100%;
  border: 2px solid gainsboro;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 25px;
  text-align: center;
  cursor: pointer;
  text-align-last: center;
  outline: none;
}
.my-select::after {
  width: 100%;
  border: 2px solid gainsboro;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 25px;
  text-align: center;
  cursor: pointer;
  text-align-last: center;
  outline: none;
}
.myInput {
  display: inline-flex;
  margin-bottom: 8px;
}
.inputBox {
  width: 100%;
}
.labelBox {
  text-align: left;
  width: 30%;
  margin-top: 10px;
}
.my-button-div {
  width: 10rem;
  margin: auto;
}
.my-button {
  margin-top: 20px;

  width: 100%;
}
label {
  font-size: 20px;
}
.el-switch {
  margin-left: 20px;
}
</style>
